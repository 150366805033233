import React from 'react';
import { Parallax } from 'rc-scroll-anim';
import './Capabilities.css';

export default function Capabilities() {
  return (
    // <div id='capabilities-section' data-aos="slide-right" data-aos-duration="1000">
    <Parallax
      animation={{ x: 0 }}
      style={{ transform: 'translateX(-400px)'}}
      className="capabilities-animation"
    >
      <div id="capabilities-section">
        <h1 className="section-header">Capabilities</h1>
        <section id="capabilities-content">
          <div id="capability-statement">
            <h3>Capability Statement</h3>
            <p>
              Elevate Government Solutions is a full-service technology
              solutions provider. Our experts have wide ranging experience
              supporting public sector agencies (Federal, State, and Local) and
              private sector entities in the creation, deployment and
              maintenance of critical solutions. We are based in Washington, DC
              and capable of serving clients across the country and around the
              globe in unclassified and classified domains. Our offerings
              include the full spectrum of technology and management consulting
              services including assessment, design, development, deployment,
              maintenance, and training.
            </p>
          </div>
          <div id="competencies">
            <h3>Core Competencies</h3>
            <ul>
              <li>Full Stack Software Development</li>
              <li>Governance Risk and Compliance</li>
              <li>Cybersecurity</li>
              <li>DevSecOps</li>
              <li>Cloud Enablement</li>
              <li>Full Service Technology Procurement</li>
              <li>Full Service Technology Selection</li>
              <li>Systems Integration</li>
              <li>Change Management</li>
              <li>Digital Transformation</li>
              <li>Project Management</li>
              <li>Agile Process Improvement</li>
              <li>UI/UX</li>
              <li>Mobile Solutions</li>
              <li>Process and Technology Training</li>
              <li>Supply Chain Management</li>
            </ul>
          </div>
          <div id="advantage">
            <h3>The Elevate Advantage</h3>
            <ul>
              <li>
                Experienced in implementation and maintenance of GRC solutions
                (MetricStream, etc.)
              </li>
              <li>
                Unique blend of veterans, public sector servants, and private
                sector technologists
              </li>
              <li>Insider perspective on government technology</li>
              <li>Experience working with and in Fortune 100 organizations</li>
              <li>Startup mentality applied to government</li>
              <li>Experience managing very large-scale engagements</li>
              <li>Strategic partnerships with a global reach</li>
              <li>PMP/CSM</li>
            </ul>
          </div>
          <div id="performance">
            <h3>Past Performance</h3>
            <ul>
              <li>United States Army</li>
              <li>United States Marine Corps</li>
              <li>National Credit Union Administration</li>
              <li>Naval Special Warfare Command</li>
              <li>NOAA</li>
              <li>New Jersey Division of Consumer Affairs</li>
              <li>New Jersey Transit</li>
              <li>New Jersey Office of Information Technology</li>
              <li>Florida Department of Education</li>
              <li>Florida Senate</li>
            </ul>
          </div>
          <div id="snapshot">
            <h3>Company Snapshot</h3>
            <p>
              <b>Cage Code</b>: 81UM5
              <br />
              <b>DUNS</b>: 081054762
              <br />
              Small Business
              <br />
              www.elvtgovt.io
              <br />
              info@elvtgovt.io
              <br />
              202.945.4833
              <br />
              450 K ST NW, 709
              <br />
              Washington, DC 20001
            </p>
          </div>
        </section>
      </div>
    </Parallax>
  );
}
