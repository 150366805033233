import React from 'react';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

export default function ParallaxSectionTwo() {
  const parallaxBanner = (
    <Parallax>
      <ParallaxBanner
        className="parallax-section"
        layers={[
          {
            image:
              'https://images.squarespace-cdn.com/content/v1/5aa975c9b10598527c176359/1523575298981-PIPMF1P85WP7WTBNMJMW/ke17ZwdGBToddI8pDm48kLkXF2pIyv_F2eUT9F60jBl7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0iyqMbMesKd95J-X4EagrgU9L3Sa3U8cogeb0tjXbfawd0urKshkc5MgdBeJmALQKw/GettyImages-627202312.jpg?format=750w',
            amount: 0.4
          }
        ]}
        style={{
          height: '500px'
        }}
      >
      </ParallaxBanner>
    </Parallax>
  );
  return <div>{parallaxBanner}</div>;
}