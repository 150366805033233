import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <footer>
      <p>
        <a href="/contact">Contact Us</a>
      </p>
      <p>CAGE: 81UM5</p>
      <p>DUNS: 081054762</p>
      <p>© Elevate Government Solutions, 2019</p>
    </footer>
  );
}
