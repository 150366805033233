import React from 'react';
import { Parallax } from 'rc-scroll-anim';
import './Team.css';

export default function Team() {
  return (
    <Parallax
      animation={{ x: 0 }}
      style={{ transform: 'translateX(400px)' }}
      className="team-animation"
    >
      <div id="team-section">
        <h1 className="section-header">Our Team</h1>
        <section id="team">
          <div id="bios">
            <div id="kevin">
              <h2>Kevin Schreck</h2>
              <h3>CEO</h3>
              <p>
                Kevin is a career long technologist and twice entrepreneur who's
                spent time working in public sector focused leadership positions
                at Northrop Grumman and Microsoft. He holds a BS in Computer
                Engineering, an MS in Software Engineering, and an MBA from the
                University of Michigan’s Ross School of Business.
              </p>
              <a href="https://www.linkedin.com/in/kevin-schreck/">LinkedIn</a>
            </div>
            <div id="jack">
              <h2>Jack Downes</h2>
              <h3>COO</h3>
              <p>
                Jack served 22 years in the US Navy culminating in Command. In
                addition to operational tours and overseas deployments, he
                worked in rapid acquisitions and advised the highest levels of
                government leadership. He's also led a small business and been
                self-employed. Jack has a BS in Ocean Engineering and an MS in
                National Security Strategy.
              </p>
              <a href="https://www.linkedin.com/in/jackdownes/">LinkedIn</a>
            </div>
            <div id="alan">
              <h2>Alan Shintaku</h2>
              <h3>Partner</h3>
              <p>
                Alan is a technology strategist and serial entrepreneur with
                several successful exits. He has worked with local, state and
                federal agencies to devise funding strategies for large public
                works projects, including several for disadvantaged communities.
                Alan holds a BS in Computer Science and an MS in Software
                Engineering.
              </p>
              <a href="https://www.linkedin.com/in/alan-shintaku-89535256/">
                LinkedIn
              </a>
            </div>
            <div id="charith">
              <h2>Charith Gamage</h2>
              <h3>Partner</h3>
              <p>
                Charith is a systems architect, analyst and technology
                evangelist who works with both emerging startups and enterprise
                organizations. With over 10 years of experience in the field, he
                develops and facilitates the implementation of technical
                initiatives; managing product design and development with a
                strong focus on high efficiency, usability, security and
                longevity.
              </p>
              <a href="https://www.linkedin.com/in/charithgamage/">LinkedIn</a>
            </div>
          </div>
        </section>
      </div>
    </Parallax>
  );
}
