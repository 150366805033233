import React from 'react';
import { Parallax } from 'rc-scroll-anim';
import Mission from './Mission';
import Focus from './Focus';
import Solutions from './Solutions';
import './Home.css';

export default function Home() {
  return (
    // <div id="home" data-aos="fade-up" data-aos-duration="1000">
    <Parallax
          animation={{ scale: 1 }}
          style={{ transform: 'scale(0.7)'}}
          className="home-animation"
        >
      <div id="home" data-aos="fade-up" data-aos-duration="1000">
        <h1 className="section-header">About Us</h1>
        <section id="statements">
          <Mission />
          <Focus />
          <Solutions />
        </section>
      </div>
    </Parallax>
  );
}