import React from 'react';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

export default function ParallaxSection() {
  const parallaxBanner = (
    <Parallax>
      <ParallaxBanner
        className="your-class"
        layers={[
          // {
          //   image: 'https://images.squarespace-cdn.com/content/v1/5aa975c9b10598527c176359/1522791131016-ID8VABYFYEM8QOJLC1WI/ke17ZwdGBToddI8pDm48kFmfxoboNKufWj-55Bgmc-J7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0iXS6XmVv7bUJ418E8Yoc1hjuviiiZmrL38w1ymUdqq4JaGeFUxjM-HeS7Oc-SSFcg/GettyImages-530435804.jpg?format=750w',
          //   amount: 0.1
          // },
          {
            image: 'https://images.squarespace-cdn.com/content/v1/5aa975c9b10598527c176359/1522790821989-ZJBX3H6ZX0JP6Y80U2IS/ke17ZwdGBToddI8pDm48kGPVK--wGoWXJsqwlxbZlQN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0mwONMR1ELp49Lyc52iWr5enfxu_O4VeONvneR-F6W8oeFhFqSrYyNrfPB9Y70_gvQ/GettyImages-177129299.jpg?format=750w',
            amount: 0.2
          }
        ]}
        style={{
          height: '500px'
        }}
      >
      </ParallaxBanner>
    </Parallax>
  );
  return <div>{parallaxBanner}</div>;
}