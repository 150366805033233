import React from 'react';

export default function Mission() {

  return (
    <section id='mission' className='home-section'>
      <h3>Mission</h3>
      <p>
        Elevate Government Solutions was founded on the belief that government technology can and should be done better. 
        We are a convergence of veterans, private sector entrepreneurs, and public sector servants that for the last few 
        decades have watched the private sector distance itself from the government space. Our mission is to drive technology, 
        drive engagement, and drive talent to shape the direction of technology across all government spaces. We aim to elevate 
        every aspect of government by providing solutions at the speed of change.
      </p>
    </section>
  )
}