import React from 'react';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

export default function ParallaxSectionThree() {
  const parallaxBanner = (
    <Parallax>
      <ParallaxBanner
        className="parallax-section"
        layers={[
          {
            image:
              'https://images.squarespace-cdn.com/content/v1/5aa975c9b10598527c176359/1522790999044-4MKHGHRU3QVIEXONWLO1/ke17ZwdGBToddI8pDm48kCHChmuivJZ1Va5ov3ZJeg17gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0ouw-8l5B_J38LMU7OZFvYcSGirBhY_3j1yQtntvGS73bypqQ-qjSV5umPUlGbQFAw/GettyImages-119713399.jpg?format=750w',
            amount: 0.4
          }
        ]}
        style={{
          height: '500px'
        }}
      >
      </ParallaxBanner>
    </Parallax>
  );
  return <div>{parallaxBanner}</div>;
}