import React from 'react';
// import { Parallax } from 'rc-scroll-anim';
import './Contact.css';

export default function Contact() {
  return (
      <div
        id="contact-section"
        data-aos="zoom-out-down"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h1 className="section-header">Contact Us</h1>
        <section id="contact">
          <div id="contact-info">
            <p>
              <a href="mailto:info@elvtgovt.io">info@elvtgovt.io</a>
            </p>
            <p>202.945.4833</p>
            <p>
              450 K ST NW, 709
              <br />
              Washington, DC 20001
            </p>
          </div>
          <div id="jobs">
            <h2>Job Postings</h2>
            <p>
              We're hiring! Join our dynamic team of developers, creators, and
              innovators today.
            </p>
            <h4>
              <a href="https://app.trinethire.com/companies/22636-elevate-government-solutions/jobs/16453-software-developer">
                Software Developer
              </a>
            </h4>
            <h4>
              <a href="https://app.trinethire.com/companies/22636-elevate-government-solutions/jobs/15486-software-developer-grc">
                Software Developer GRC
              </a>
            </h4>
          </div>
        </section>
      </div>
  );
}
