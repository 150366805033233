import React from 'react';

export default function Solutions() {
  return (
    <section id='solutions' className='home-section'>
      <h3>Solutions</h3>
      <p>
        <b>Agile App Development</b><br/>
        Using both open source and proprietary technologies, our US based teams develop 
        and maintain cutting edge applications. Our Agile approach allows for rapid 
        development and flexibility to adapt to changing requirements 
      </p>
      <p>
        <b>DEVSECOPS</b><br/>
        We bring a people first approach to DevSecOps that evaluates available tools 
        with the skillsets and needs of our clients to create secure pipelines that 
        drive productivity and innovation. We believe in best practices and not a 
        one-size-fits-all approach
      </p>
      <p>
        <b>Governance Risk and Compliance</b><br/>
        Making use of the best products in the space, we are able to architect and 
        deliver GRC solutions that make the most of out-of-the-box functionality with 
        custom code to tailor the solution to the unique needs of our clients.
      </p>
      <p>
        <b>Cybersecurity</b><br/>
        At the forefront of concern is the security of our nation's technology and data. 
        We partner with our government clients to ensure the safety, security and reliability 
        of their solutions and networks making them robust and resistant to attack.
      </p>
    </section>
  )
}