import React from 'react';
// import { Parallax } from 'react-scroll-parallax';
import { Parallax as RCParallax } from 'rc-scroll-anim';
import Particles from 'react-particles-js';
import './Header.css';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    console.log(
      'Hello developer! There are',
      Math.floor(this.state.width / 50),
      'particles on the header!'
    );
    return (
      <header data-aos="fade-down" data-aos-duration="2000">
        <RCParallax
          animation={[{ opacity: 1, playScale: [0, 0.5] }, { y: 0 }]}
          always={true}
          ease="easeInQutQuad"
          style={{ opacity: 0, transform: 'translateY(100px)' }}
          className="header-animation"
        >
          <h1 id="header-text">
            <img
              alt="elevate government solutions small logo"
              id="header-logo"
              src="https://images.squarespace-cdn.com/content/5aa975c9b10598527c176359/1522938383692-IEWUDUSK01QSVS72I4NO/400px.png?content-type=image%2Fpng"
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="500"
            />
            <br />
            <span
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1000"
              id="solutions-span"
            >
              Solutions
            </span>
            <span> </span>
            <br />
            <span
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1100"
            >
              at
            </span>
            <span> </span>
            <span
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1200"
            >
              the
            </span>
            <span> </span>
            <span
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1400"
            >
              Speed
            </span>
            <span> </span>
            <span
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1500"
            >
              of
            </span>
            <span> </span>
            <br />
            <span
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="1700"
              id="change-span"
            >
              Change
            </span>
          </h1>
          <span
            id="header-arrow"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="2600"
          >
            ▼
          </span>
        </RCParallax>
        <Particles
          params={{
            particles: {
              number: {
                value: Math.floor(this.state.width / 50)
              },
              size: {
                value: 3
              },
              color: {
                value: '#333333'
              },
              line_linked: {
                shadow: {
                  enable: true,
                  color: '#fefefe',
                  blur: 10
                }
              }
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'repulse'
                }
              }
            }
          }}
          width="100vw"
          height="100vh"
          style={{
            margin: '-550px 0 0 0',
            width: '100%',
            height: '100%'
          }}
          id="particles"
        />
      </header>
    );
  }
}
