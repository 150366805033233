import React from 'react';

export default function Focus() {
  return (
    <section id='focus' className='home-section'>
      <h3>Focus</h3>
      <p>
        Our focus is to bring the technology expertise we've honed in the private sector domain, 
        mix it with our experience working for and with public sector agencies, and apply it to 
        the demands of today's government. We very much live Agile and apply this mindset and 
        methodology both internally and with our clients. We think strategically and long term to 
        shape solutions that are reactive while allowing flexibility to change to meet demands over time. 
        Success is viewed both in the immediate and in a solution's ability to deliver results over time.
      </p>
      <p>
        Looking outside of ourselves, we also strive to reshape a public sector environment that for too 
        long has stood in its own way. We partner with educational institutions and community organizations 
        to drive dialogs for change and engage a technology talent pool that has increasingly declined to 
        participate in driving the technology change we need to compete globally.
      </p>
    </section>
  )
}