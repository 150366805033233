import './App.css';
import React from 'react';
import Footer from './Components/Footer';
import Home from './Components/Home/Home';
import Capabilities from './Components/Home/Capabilities';
import Team from './Components/Home/Team';
import Contact from './Components/Home/Contact';
import Header from './Components/Home/Header';
import ParallaxSection from './Components/Home/ParallaxSection';
import ParallaxSectionTwo from './Components/Home/ParallaxSectionTwo';
import ParallaxSectionThree from './Components/Home/ParallaxSectionThree';

export default function App() {
  document.title = 'Elevate | Government Solutions at the Speed of Change';
  return (
    <div className="App">
      <Header />
      <main role='main'>
        <Home />
        <ParallaxSection />
        <Capabilities />
        <ParallaxSectionTwo />
        <Team />
        <ParallaxSectionThree />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}